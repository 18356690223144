import { OwnUpModalController } from '@rategravity/own-up-component-library';
import { CancelX } from '@rategravity/own-up-component-library/components/images/icons/svgs';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import React, { useEffect, useRef } from 'react';
import { colors } from '../../../modules/colors';
import { Catchphrase, PrimaryText } from '../../typography';
import {
  BackgroundImage,
  ContentWrapper,
  ImageWrapper,
  OwnUpModal,
  OwnUpModalBackdrop,
  OwnUpModalContainer,
  OwnUpModalSpacerBottom,
  OwnUpModalSpacerTop,
  OwnUpModalXButton,
  TextWrapper
} from './components';

// eslint-disable-next-line max-lines-per-function
export const ReviewModal = ({
  Image,
  data: { catchphrase, review, name },
  controller,
  finalFocusRef
}: {
  Image: JSX.Element | null;
  data: {
    catchphrase: string | JSX.Element;
    name: string;
    review: JSX.Element;
  };
  controller: OwnUpModalController;
  finalFocusRef: React.RefObject<HTMLElement>;
}) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef && contentRef.current) {
      contentRef.current.scrollTop = 0;
      controller.state.visible
        ? disableBodyScroll(contentRef.current)
        : enableBodyScroll(contentRef.current);
    }
  }, [controller.state.visible]);

  return (
    <>
      <OwnUpModalBackdrop aria-label="modal backdrop" {...controller.state} />
      <OwnUpModal
        hideOnClickOutside
        preventBodyScroll={true}
        {...controller.state}
        /* eslint-disable-next-line @typescript-eslint/naming-convention */
        unstable_finalFocusRef={finalFocusRef}
        aria-label={`review by ${name}`}
      >
        <OwnUpModalContainer ref={contentRef}>
          <OwnUpModalSpacerTop />
          <BackgroundImage>
            <ImageWrapper>{Image}</ImageWrapper>
          </BackgroundImage>
          <ContentWrapper>
            <TextWrapper>
              <Catchphrase tabIndex="0">{catchphrase}</Catchphrase>
            </TextWrapper>
            <PrimaryText>{review}</PrimaryText>
          </ContentWrapper>
          <OwnUpModalSpacerBottom />
        </OwnUpModalContainer>
        <OwnUpModalXButton onClick={controller.hide} aria-label="close">
          <CancelX color={colors.PRIMARY_ALT} height="100%" strokeWidth={1} />
        </OwnUpModalXButton>
      </OwnUpModal>
    </>
  );
};
