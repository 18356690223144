import { buildMediaQueries } from '@rategravity/marketing-components';
import { zIndex } from '@rategravity/own-up-component-library';
import { Dialog, DialogBackdrop } from 'reakit';
import styled, { css } from 'styled-components';
import DotsSVG from '../../../images/reviews/green-dots-reviews.svg';
import { colors } from '../../../modules/colors';

/**
 * Background that renders over the page when the modal is opened.
 */
export const OwnUpModalBackdrop = styled(DialogBackdrop)`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: ${zIndex.ModalBackdrop};
`;

/**
 * Styled dialog (e.g., where it sits in relation
 * to the page).
 */
export const OwnUpModal = styled(Dialog)`
  position: fixed;
  left: 0;
  right: 0;
  max-width: 75vw;
  margin: auto;
  top: 5%;
  white-space: nowrap;
  z-index: ${zIndex.ModalWindow};
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  opacity: 0;
  transform: '';
  ${buildMediaQueries(['medium', 'large'], {
    '&[data-enter]': {
      opacity: 1,
      transform: 'translate(-15px)'
    }
  })}
  ${buildMediaQueries('smallAndDown', {
    left: 0,
    width: '100%',
    maxWidth: '100vw',
    minWidth: 'unset',
    '&[data-enter]': {
      opacity: 1
    }
  })}
`;

/**
 * Styling for divs that are used to provide spacing
 * between the content and the top and bottom of the
 * dialog proper.
 *
 * zIndex is used to ensure the image does not
 * obscure the header.
 *
 * Width adjustment is twice padding for container, minus spacing for X button
 */
const ModalSpacerStyle = css`
  background-color: ${colors.BACKGROUND};
  height: 60px;
  left: 45px;
  width: calc(75vw - 100px);
  position: absolute;
  z-index: ${zIndex.ModalWindow};
`;

export const OwnUpModalSpacerTop = styled('div')`
  ${ModalSpacerStyle};
  top: 0;
  ${buildMediaQueries('smallAndDown', {
    borderRadius: 'unset',
    height: 15,
    left: 0,
    width: '100vw'
  })}
`;

export const OwnUpModalSpacerBottom = styled('div')`
  ${ModalSpacerStyle};
  bottom: 0;
  ${buildMediaQueries('smallAndDown', {
    borderRadius: 'unset',
    height: 15,
    left: 0,
    width: '100vw'
  })}
`;

/**
 * Styling for the container for the content of the modal.
 */
export const OwnUpModalContainer = styled('section')`
  background-color: ${colors.BACKGROUND};
  border-radius: 7px;
  box-shadow: 0px 2px 50px rgba(50, 50, 50, 0.4);
  max-height: 75vh;
  max-width: 75vw;
  overflow: auto;
  padding: 60px;
  ${buildMediaQueries('smallAndDown', {
    borderRadius: 'unset',
    height: '100vh',
    maxWidth: '100vw',
    minWidth: 'unset',
    padding: 30,
    width: 'calc(100vw - 60px)'
  })}
`;

export const OwnUpModalXButton = styled('button')`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  position: absolute;
  top: 30px;
  right: 30px;
  height: 24px;
  & > svg {
    height: 100%;
    stroke: ${colors.PRIMARY_ALT};
    fill: ${colors.PRIMARY_ALT};
  }
  ${buildMediaQueries('smallAndDown', {
    top: '15px',
    right: '15px'
  })}
`;

export const BackgroundImage = styled('div')`
  background-image: url(${DotsSVG});
  background-position: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  ${buildMediaQueries('smallAndDown', {
    width: 'calc(100% - 15px)',
    marginLeft: 0
  })}
`;

export const ImageWrapper = styled('div')`
  width: 100%;
  margin: auto;
  max-width: 230px;
  text-align: center;
`;

export const ContentWrapper = styled('div')`
  width: 100%;
  margin: auto;
  white-space: normal;
  ${buildMediaQueries('smallAndDown', {
    width: 'calc(100% - 15px)',
    marginLeft: 0
  })}
`;

export const TextWrapper = styled('div')`
  margin: 20px 0;
`;
