import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox
} from '@rategravity/own-up-component-library';
import React, { useRef } from 'react';
import { useMixpanel } from '../../../hooks/use-mixpanel';
import DotsSVG from '../../../images/reviews/green-dots-reviews.svg';
import StarsSVG from '../../../images/reviews/yotpo-5-stars.svg';
import { UnderlinedButton } from '../../links';
import { BoldPrimaryText, Catchphrase, PrimaryText } from '../../typography';
import { ReviewModal } from '../review-modal';
import { useModalController } from '../review-modal/controller';

const BackgroundImageStyle = createOwnUpStyle({
  backgroundImage: `url(${DotsSVG})`,
  backgroundPosition: '50%',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat'
});

const BackgroundImage = createOwnUpComponent(OwnUpBox, BackgroundImageStyle);

export const imageStyle = {
  display: 'block',
  maxWidth: 390,
  margin: 'auto',
  position: 'relative' as const,
  borderRadius: '50%'
};

const ImageWrapperStyle = createOwnUpStyle({
  width: '75%',
  margin: 'auto',
  textAlign: 'center',
  variants: {
    reviewHeadshot: {
      '& img': {
        borderRadius: '50%'
      }
    }
  }
});

const ImageWrapper = createOwnUpComponent(OwnUpBox, ImageWrapperStyle);

const ContentWrapperStyle = createOwnUpStyle({
  width: '100%',
  margin: 'auto',
  textAlign: 'center'
});

const ContentWrapper = createOwnUpComponent(OwnUpBox, ContentWrapperStyle);

const Stars = () => (
  <div style={{ margin: 'auto', maxWidth: 209 }}>
    <img alt="5-star review" src={StarsSVG} />
  </div>
);

const TextWrapperStyle = createOwnUpStyle({
  margin: '20px 0'
});

const TextWrapper = createOwnUpComponent(OwnUpBox, TextWrapperStyle);

// eslint-disable-next-line max-lines-per-function
export const Review = ({
  Image,
  data: { name, catchphrase, shortReview, review },
  flip = false
}: {
  Image: JSX.Element | null;
  data: {
    name: string;
    catchphrase: string | JSX.Element;
    shortReview: string | JSX.Element;
    review: JSX.Element;
  };
  flip?: boolean;
}) => {
  const controller = useModalController();

  const linkRef = useRef<HTMLAnchorElement>(null);
  const mixpanel = useMixpanel();

  return (
    <section style={{ padding: '0 30px' }}>
      <BackgroundImage style={flip ? { transform: 'scaleX(-1)' } : {}}>
        <ImageWrapper variant="reviewHeadshot" style={flip ? { transform: 'scaleX(-1)' } : {}}>
          {Image}
        </ImageWrapper>
      </BackgroundImage>
      <ContentWrapper>
        <div style={{ marginTop: '20px' }}>
          <BoldPrimaryText>{name}</BoldPrimaryText>
        </div>
        <ImageWrapper>
          <Stars />
        </ImageWrapper>
        <TextWrapper>
          <Catchphrase>{catchphrase}</Catchphrase>
        </TextWrapper>
        <PrimaryText>{shortReview}</PrimaryText>
        <TextWrapper>
          <UnderlinedButton
            ref={linkRef}
            onClick={() => {
              controller.show();
              mixpanel.track('Reviews action', { action: 'read-more-review' });
            }}
          >
            Read More &gt;
          </UnderlinedButton>
        </TextWrapper>
      </ContentWrapper>
      <ReviewModal
        Image={Image}
        data={{ catchphrase, name, review }}
        controller={controller}
        finalFocusRef={linkRef}
      />
    </section>
  );
};
