import { getImage, ImageQuery } from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox,
  OwnUpColumns
} from '@rategravity/own-up-component-library';
import React, { PropsWithChildren } from 'react';
import * as reviewsData from '../../data/content/reviews';
import { metadata } from '../../data/metadata/images';
import { imageStyle, Review } from './review';

const reviewers = [
  { imageName: 'evan.jpg', review: 'evan' as const },
  { imageName: 'andrew-review.jpg', review: 'andrew' as const },
  { imageName: 'michael-and-marina.jpg', review: 'michael' as const }
];

const SectionStyle = createOwnUpStyle({ margin: '0 30px' });
const Section = createOwnUpComponent('section', SectionStyle);

const ReviewsWrapperStyle = createOwnUpStyle({
  margin: 'auto',
  variants: {
    smallAndDown: {
      maxWidth: 400
    }
  }
});

export const ReviewsWrapper = createOwnUpComponent(OwnUpColumns, ReviewsWrapperStyle);

const ReviewWrapperStyle = createOwnUpStyle({
  alignSelf: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  flexBasis: '33%',
  flexGrow: 'unset',
  justifyContent: 'center',
  maxWidth: 'unset',
  variants: {
    smallAndDown: {
      flexBasis: '100%',
      marginBottom: 30
    }
  }
});

export const ReviewWrapper = createOwnUpComponent(OwnUpBox, ReviewWrapperStyle);

export const Reviews = ({ imageData }: { imageData: ImageQuery }) => {
  const reviews = reviewers.map((reviewer, index) => {
    const Image = getImage(imageData, reviewer.imageName, metadata, imageStyle);

    return (
      <ReviewWrapper key={`review-${reviewer.review}`}>
        <Review Image={Image} data={reviewsData[reviewer.review]} flip={Boolean(index % 2)} />
      </ReviewWrapper>
    );
  });

  return <ReviewsWrapper count={{ small: 1, medium: 3 }}>{reviews}</ReviewsWrapper>;
};

export const ReviewsFeatured = ({ children }: PropsWithChildren<{}>) => (
  <Section aria-label="Featured Reviews">{children}</Section>
);
