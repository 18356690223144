import { useDialogState } from 'reakit';

export const useModalController = () => {
  const state = useDialogState({ animated: true });

  return {
    show: state.show,
    hide: state.hide,
    toggle: state.toggle,
    state
  };
};
