import React from 'react';
import { BoldPrimaryText, PrimaryText } from '../../../components/typography';

export const michael = {
  name: 'Michael and Marina',
  catchphrase: <>&#8220;There was a human touch ... it felt like a concierge.&#8221;</>,
  shortReview:
    'Michael and his wife were living and working in the greater Boston area when they started to think about buying a home...',
  review: (
    <>
      <PrimaryText>
        Michael and his wife were living and working in the Greater Boston area when they started to
        think about buying a home. They were trying to navigate the process as first-time homebuyers
        when they happened upon a seminar led by Own Up.
      </PrimaryText>
      <br />
      <br />
      <BoldPrimaryText>
        <em>
          &#8220;We were looking for ways to become more educated about the home buying process and
          serendipitously the building I was working in had advertisements for a first time
          homebuyers course held by Own Up. My wife joined me for the seminar with Mike. We had
          learned a lot on our own but it was super helpful to have a seasoned expert calmly
          explaining different pieces of the process to us, answering our questions, and confirming
          the information we had found.&#8221;
        </em>
      </BoldPrimaryText>
      <br />
      <br />
      <PrimaryText>
        A year later, they were ready to jump into the process by getting pre-approved for a loan
        and reached out to Own Up.
      </PrimaryText>
      <br />
      <br />
      <BoldPrimaryText>
        <em>
          &#8220;I kept a business card from the presentation somewhere near my desk and fished it
          out when we were ready to do a pre-approval. Initially I pulled up Own Up&#8217;s website
          and used the online tool to apply for a pre-approval. It was really easy to use and I
          hadn&#8217;t realized that we could use the pre-approval process as a way to figure out
          how much we could afford. With the online tools we didn’t have to just bust the numbers on
          our own.&#8221;
        </em>
      </BoldPrimaryText>
      <br />
      <br />
      <PrimaryText>
        Michael and Marina knew they wanted to work with a local bank and an Own Up home advisor
        worked with them to find a competitive loan in New England.
      </PrimaryText>
      <br />
      <br />
      <BoldPrimaryText>
        <em>
          &#8220;All of the rates Mike pulled for us were much better than what we had expected
          based on the publicly available tools. And not only that, but he was able to restrict our
          search to local lenders which was really important to us.&#8221;
        </em>
      </BoldPrimaryText>
      <br />
      <br />
      <PrimaryText>
        Beyond finding them a number of competitive rates with local lenders, Mike helped Michael
        and Mariana understand the various mortgage options out on the market and continued to
        advise them throughout their home search.
      </PrimaryText>
      <br />
      <br />
      <BoldPrimaryText>
        <em>
          &#8220;There are so many players out there in the industry &#8212; so many attorneys, so
          many agents &#8212; it’s bewildering to find the right place or person to start with. With
          Own Up there was this human touch there and it almost felt like a concierge at times. Our
          work with them went as well as we could have hoped. It felt like we were getting way more
          than our value&#8217;s worth.&#8221;
        </em>
      </BoldPrimaryText>
    </>
  )
};
